<template>
  <div class="row mx-2 my-0">
    <div
      class="
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
        card
        theme-card
        border-0
        py-5
        px-4
      "
    >
      <h1 class="mb-5">{{ isEditMode ? "Edit" : "Create" }} Consent</h1>
      <alert v-if="consentLoading" class="light-shadow mx-4 mb-4" />
      <form
        v-else
        @submit.prevent="createConsentHelper"
        validate
        class="d-flex flex-column"
      >
        <input-control
          type="horizontal"
          v-model.trim="consent.title"
          labelClass="col-md-3 col-lg-2"
          required
          maxLength="255"
        >
          Title
        </input-control>
        <input-control
          type="horizontal"
          v-model.trim="consent.description"
          control="textarea"
          rows="6"
          required
          maxLength="500"
          labelClass="col-md-3 col-lg-2"
        >
          Description
        </input-control>
        <save
          classes="save-button btn btn-theme align-self-end"
          :saving="createConsentLoading || updateConsentLoading"
        >
          {{ isEditMode ? "Update" : "Save" }}
        </save>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CreateConsent",
  data() {
    return {
      consent: {
        title: "",
        description: "",
      },
    };
  },
  async mounted() {
    if (this.consentId) {
      const data = await this.getConsent(this.consentId);
      this.consent = {
        title: data.title,
        description: data.description,
      };
    }
  },
  computed: {
    ...mapState({
      consentLoading: (state) => state.consents.consent.loading,
      createConsentLoading: (state) => state.consents.createConsentLoading,
      updateConsentLoading: (state) => state.consents.updateConsentLoading,
    }),
    consentId() {
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return null;
    },
    isEditMode() {
      return !!this.consentId;
    },
  },
  methods: {
    ...mapActions({
      getConsent: "consents/getConsent",
      createConsent: "consents/createConsent",
      updateConsent: "consents/updateConsent",
    }),
    createConsentHelper: function () {
      if (this.isEditMode) {
        this.updateConsent({
          id: this.consentId,
          ...this.consent,
        });
      } else {
        this.createConsent(this.consent);
      }
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
};
</script>
